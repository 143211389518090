var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('data-table',{attrs:{"modelName":"user"},scopedSlots:_vm._u([{key:"input-field:mobileNo",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"my-2",attrs:{"value":value,"label":_vm.$vuetify.lang.t('$vuetify.pages.login.mobileNumber'),"outlined":"","name":"mobile","autocomplete":"mobile","rules":[
            _vm.$globalRules.required(
              _vm.$vuetify.lang.t("$vuetify.validation.required")
            )[0],
            _vm.$globalRules.mobile(
              _vm.$vuetify.lang.t("$vuetify.validation.mobileNumber")
            )[0]
          ],"counter":11,"type":"tel","required":"","dense":""},on:{"input":function (v) { return change(_vm.inputMobile(v)); }}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }