<template>
  <v-container fluid>
    <data-table modelName="user">
      <template #[`input-field:mobileNo`]="{ value, change }">
        <v-col cols="12" md="6">
          <v-text-field
            :value="value"
            @input="v => change(inputMobile(v))"
            :label="$vuetify.lang.t('$vuetify.pages.login.mobileNumber')"
            outlined
            name="mobile"
            autocomplete="mobile"
            :rules="[
              $globalRules.required(
                $vuetify.lang.t(`$vuetify.validation.required`)
              )[0],
              $globalRules.mobile(
                $vuetify.lang.t(`$vuetify.validation.mobileNumber`)
              )[0]
            ]"
            :counter="11"
            type="tel"
            required
            dense
            class="my-2"
          >
          </v-text-field>
        </v-col>
      </template>
    </data-table>
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";
import { englishNumber } from "@/tools/functions";
export default {
  components: {
    DataTable
  },
  methods: {
    inputMobile(value) {
      return englishNumber(value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
